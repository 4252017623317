import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";
import "./Footer.css";

const Footer = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {}, [dispatch]);

  return (
    <div className="Footer-container">
      <div className="Footer-top">
        <Link
          id="item1"
          style={{ textDecoration: "none" }}
          className="Footer-links-item"
          to="/"
        >
          Portfolio
        </Link>
        <Link
          id="item2"
          style={{ textDecoration: "none" }}
          className="Footer-links-item"
          to="/services"
        >
          About
        </Link>
        <Link
          id="item3"
          style={{ textDecoration: "none" }}
          className="Footer-links-item"
          to="/services#services"
        >
          Services
        </Link>
        <Link
          id="item4"
          style={{ textDecoration: "none" }}
          className="Footer-links-item"
          to="/reviews"
        >
          What They Say
        </Link>
        <Link
          id="item5"
          style={{ textDecoration: "none" }}
          className="Footer-links-item"
          to="/Contact"
        >
          Contact
        </Link>
      </div>
      <div className="Footer-bottom">
        <div className="Footer-bottom-left">
          Copyright © 2020 Amy's Always Write. All rights reserved. <br />
          built by Phil LaGambino
        </div>
        <div className="Footer-bottom-right">
          Amy's Always Write <br />
          Webster, NY 14580
        </div>
      </div>
    </div>
  );
};

export default Footer;
