import * as actionTypes from "../actions/actionTypes";

const initialState = {
  services: [],
  reviews: [],
  portfolio: [],
};

const fetchData = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DATA:
      return {
        ...state,
        services: action.services,
        reviews: action.reviews,
        portfolio: action.portfolio,
      };
    default:
      return state;
  }
};

export default fetchData;
