import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./Home.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import AboutMe from "../../components/AboutMe/AboutMe";
import Portfolio from "../../components/Portfolio/Portfolio";
import ScreenBreaker from "../../components/ScreenBreaker/ScreenBreaker";

const Home = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispatch]);

  return (
    <div className="Home-container">
      <Header history={props.history} />
      <div className="Home-body-container">
        <AboutMe />
        <ScreenBreaker />
        <Portfolio />
        <ScreenBreaker />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
