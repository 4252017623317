import React from "react";
import Img from "react-image";
import ClipLoader from "react-spinners/ClipLoader";
import "./PortfolioItem.css";

const PortfolioItem = (props) => {
  return (
    <div className="Portfolio-item-container">
      <Img
        className="Portfolio-item"
        src={props.link}
        alt="new"
        data-aos="fade-in"
        loader={<ClipLoader />}
      />
      <div className="Portfolio-info-container">
        <span className="Portfolio-title">{props.title}</span>
        <span className="Portfolio-summary">{props.info}</span>
        <span className="Portfolio-summary">{props.summary}</span>
      </div>
    </div>
  );
};

export default PortfolioItem;
