import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";
import "./HeaderLinks.css";

const HeaderLinks = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {}, [dispatch]);

  return (
    <div className="Header-links-container">
      <Link
        id="item1"
        style={{ textDecoration: "none" }}
        className="Header-links-item"
        to="/"
      >
        Portfolio
      </Link>
      <Link
        id="item2"
        style={{ textDecoration: "none" }}
        className="Header-links-item"
        to="/services"
      >
        About
      </Link>
      <Link
        id="item3"
        style={{ textDecoration: "none" }}
        className="Header-links-item"
        to="/services#services"
      >
        Services
      </Link>
      <Link
        id="item4"
        style={{ textDecoration: "none" }}
        className="Header-links-item"
        to="/reviews"
      >
        What They Say
      </Link>
      <Link
        id="item5"
        style={{ textDecoration: "none" }}
        className="Header-links-item"
        to="/Contact"
      >
        Contact
      </Link>
    </div>
  );
};

export default HeaderLinks;
