import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import logo from "../../../assests/amy_gif.gif";
import "./HeaderLogo.css";

const HeaderLogo = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {}, [dispatch]);

  return (
    <div className="Header-logo-conatiner">
      <img className="Header-logo-image" src={logo} alt="new" />
    </div>
  );
};

export default HeaderLogo;
