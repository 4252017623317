import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./AboutMe.css";

const AboutMe = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispatch]);

  return (
    <div className="About-me-container">
      <div className="About-me-subheader">
        <div className="About-me-subheader-your">Your Words:&nbsp;</div>
        <div className="About-me-subheader-italic">
          Cleaned & Pressed Into Place
        </div>
      </div>
      <div className="About-me-body-container">
        At Amy’s Always Write, the design supports your message. Accuracy is a
        forethought. As much care is taken to assure grammar and details are
        correct as is applied to the utility of the design, because all reflect
        your organization’s professional image.
      </div>
    </div>
  );
};

export default AboutMe;
