import * as actionTypes from "./actionTypes";
import firebase from "firebase";

export const fetchAllData = () => {
  return async (dispatch) => {
    try {
      var dataRef = firebase
        .database()
        .ref("1Nb8xOgY37RbOO8h306Qew2Er-XtU6TvQk21jsghGepY/");
      dataRef.on("value", function (snap) {
        const services = [];
        const reviews = [];
        const portfolio = [];

        for (const key in snap.val()) {
          if (key === "services") {
            for (const value in snap.val()[key]) {
              services.push(snap.val()[key][value]);
            }
          } else if (key === "reviews") {
            for (const value in snap.val()[key]) {
              reviews.push(snap.val()[key][value]);
            }
          } else if (key === "portfolio") {
            for (const value in snap.val()[key]) {
              portfolio.push(snap.val()[key][value]);
            }
          }
        }

        dispatch({
          type: actionTypes.FETCH_DATA,
          services: services,
          reviews: reviews,
          portfolio: portfolio,
        });
      });
    } catch (err) {
      console.log(err);
    }
  };
};
