import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Reviews.css";
import Header from "../../components/Header/Header";
import Review from "../../components/Review/Review";
import Footer from "../../components/Footer/Footer";

const Reviews = (props) => {
  const dispatch = useDispatch();
  const reviews = useSelector((state) => state.fetchData.reviews);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispatch]);

  const reviewsMapped = reviews.map((review, index) => {
    return <Review key={index} review={review} val={index} />;
  });

  return (
    <div className="Reviews-container">
      <Header history={props.history} />
      <div className="Reviews-header-info">
        <div className="Reviews-header-item" id="services">
          Testimonials
        </div>
      </div>
      <div className="Reviews-body-container">{reviewsMapped}</div>
      <Footer />
    </div>
  );
};

export default Reviews;
