import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Footer from "../../components/Footer/Footer";
import "./Contact.css";
import Header from "../../components/Header/Header";

const Contact = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispatch]);

  return (
    <div className="Coantact-container">
      <Header history={props.history} />
      <div className="Contact-body-container">
        <div className="Contact-header">Contact</div>
        <div className="Contact-body">
          Let's discuss your publication needs!
        </div>
        <div className="Contact-body-bold" style={{ marginTop: "1vh" }}>
          585-260-2499 (Cell)
        </div>
        <div className="Contact-body-bold" style={{ marginTop: "1vh" }}>
          585-872-7558
        </div>
        <div className="Contact-body-bold" style={{ marginTop: "1vh" }}>
          alagambino@gmail.com
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
