import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { TiDocumentText } from "react-icons/ti";
import { GoBook } from "react-icons/go";
import { GiFoldedPaper, GiPapers, GiNewspaper } from "react-icons/gi";
import { FaRegNewspaper } from "react-icons/fa";
import "./ServiceItem.css";

const ServiceItem = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispatch]);

  let icon = null;

  switch (props.service.icon) {
    case "TiDocumentText":
      icon = (
        <TiDocumentText className="Service-icon" size="6vh" color="#27738D" />
      );
      break;
    case "GoBook":
      icon = <GoBook className="Service-icon" size="6vh" color="#27738D" />;
      break;
    case "GiFoldedPaper":
      icon = (
        <GiFoldedPaper className="Service-icon" size="6vh" color="#27738D" />
      );
      break;
    case "GiPapers":
      icon = <GiPapers className="Service-icon" size="6vh" color="#27738D" />;
      break;
    case "FaRegNewspaper":
      icon = (
        <FaRegNewspaper className="Service-icon" size="6vh" color="#27738D" />
      );
      break;
    case "GiNewspaper":
      icon = (
        <GiNewspaper className="Service-icon" size="6vh" color="#27738D" />
      );
      break;
    default:
      return null;
  }

  return (
    <div className="Service-item-container">
      <div className="Service-item-info">
        <div className="Service-item-header-contianer">
          {icon}
          <div className="Service-item-info-header">{props.service.title}</div>
        </div>
        <div className="Service-item-info-summary">{props.service.summary}</div>
      </div>
      <div className="Service-item-images-container"></div>
    </div>
  );
};

export default ServiceItem;
