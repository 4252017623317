import React, { useState, useEffect } from "react";
import Home from "./containers/Home/Home";
import { useDispatch } from "react-redux";
import Services from "./containers/Services/Services";
import Reviews from "./containers/Reviews/Reviews";
import Contact from "./containers/Contact/Contact";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import * as fetchData from "./store/actions/fetchData";
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  AOS.init();
  const dispatch = useDispatch();
  const root = document.documentElement;
  const [isLoading, setIsLoading] = useState(false);
  root.style.setProperty("--secondary", "#531D68");
  root.style.setProperty("--triad", "#FFEB70");
  root.style.setProperty("--primary", "#27738D");
  root.style.setProperty("--alternate", "#97C9D8");

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchData.fetchAllData()).then(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  if (isLoading) {
    return <div></div>;
  }
  return (
    <div className="App">
      <Switch>
        <Route path="/services" exact component={Services} />
        <Route path="/reviews" exact component={Reviews} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/" exact component={Home} />
      </Switch>
    </div>
  );
};

export default App;
