import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Services.css";
import Header from "../../components/Header/Header";
import ServiceItem from "../../components/ServiceItem/ServiceItem";
import ScreenBreaker from "../../components/ScreenBreaker/ScreenBreaker";
import Footer from "../../components/Footer/Footer";

const Services = (props) => {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.fetchData.services);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispatch]);

  const servicesMapped = services.map((service, index) => {
    return <ServiceItem key={index} service={service} val={index} />;
  });

  return (
    <div className="Services-container">
      <Header history={props.history} />
      <div className="Services-about-me-container">
        <div className="Services-header-item">About Me</div>
        <ScreenBreaker />
        <div className="Services-about-me-summary">
          Hi, I’m Amy LaGambino and I started Amy's Always Write in 2004. I’m
          proud to still serve some of my original clients. My approach to
          design is different than many others because it is communications
          driven. I’ve seen beautiful graphic designs, but the artistic approach
          often falls short of delivering information clearly. I graduated from
          Cornell University with a degree in communications, where I developed
          this perspective for creating print messages based on clarity and
          effectiveness.
        </div>
        <ScreenBreaker />
        <div className="Services-header-item" id="services">
          Services
        </div>
        {servicesMapped}
      </div>
      <Footer />
    </div>
  );
};

export default Services;
