import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ScreenBreaker from "../ScreenBreaker/ScreenBreaker";
import "./Review.css";

const Review = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispatch]);

  return (
    <div
      className="Review-item-container"
      data-aos="fade-right"
      id={"Review-id-" + (props.val % 2)}
    >
      <div className="Review-item-info-summary">{props.review.review}</div>
      <div className="Review-item-info-header">{props.review.quote}</div>
      <ScreenBreaker />
    </div>
  );
};

export default Review;
