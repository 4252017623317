import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./Header.css";
import HeaderLogo from "./HeaderLogo/HeaderLogo";
import HeaderLinks from "./HeaderLinks/HeaderLinks";

const Header = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {}, [dispatch]);

  return (
    <div className="Header-container">
      <div className="Header-top">
        <HeaderLinks history={props.history} />
      </div>
      <div className="Header-body-container">
        <HeaderLogo />
      </div>
    </div>
  );
};

export default Header;
