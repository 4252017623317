import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PortfolioItem from "./PortfolioItem/PortfolioItem";
import "./Portfolio.css";

const Portfolio = (props) => {
  const dispatch = useDispatch();
  const pictures = useSelector((state) => state.fetchData.portfolio);

  const picArrMapped = pictures.map((picture, index) => {
    return (
      <PortfolioItem
        link={picture.imageUrl}
        title={picture.title}
        info={picture.info}
        summary={picture.summary}
        key={index}
      />
    );
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispatch]);

  return (
    <div className="Portfolio-container">
      <div className="Portfolio-header">Portfolio</div>
      <div className="Portoflio-grid-container">
        <div className="Portfolio-grid">{picArrMapped}</div>
      </div>
    </div>
  );
};

export default Portfolio;
